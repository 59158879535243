import React, { useState } from 'react'
import emailjs from '@emailjs/browser';
import './home.css'
const Home = () =>
{
  const [display, setDisplay] = useState(false)
  const [error, setError] = useState(false)
  const [active, setActive] = useState(false)
  const sendEmail = (e) =>
  {
    e.preventDefault();
    if (e.target.email.value === "") setError(true)
    else
    {
      emailjs.sendForm('service_67h77ry', 'template_y0wsn23', e.target, 'RchvYePq1KQGpvoxg')
        .then((result) =>
        {
          setDisplay(true)
          setError(false)
        }, (error) =>
        {
          console.log(error.text);
        });
      e.target.reset()
    }
  };

  return (
    <>
      <div className='home__page__container'>
        <div className="first__div">
          <header className='header'>
            <div className="header__img">
              <img src="/logosvg.svg" alt="" />
            </div>
            <div className='header__text'>
              <p>Votre agence digitale</p>
              <p>spécialisée dans les métiers de la santé</p>
            </div>
          </header>
          <main>
            <p className="title">
              COMING <br />
              SOON!
            </p>
            {!display ? (
              <form action="" onSubmit={sendEmail}>
                <input type="email" name="email" id="email" placeholder='Veuillez entrer votre email *' />
                <button>M'avertir </button>
                {error && <p style={{ color: "red", fontSize: 24, fontWeight: "bold", margin: "12px" }}>Ce champ ne doit pas être vide</p>}
              </form>
            ) : (
              <div style={{ display: "flex", marginTop: 16, alignItems: "center", fontSize: 24 }} className="section1-message" label="succes">
                <img style={{ marginRight: 12 }} src="succes-icon.svg" alt="" width="45px" height="45px" />
                {" "} Nous vous contacterons dès que possible
              </div>
            )}
            <p className='prevent'>Prévenez-moi lorsque la Com'Primée est lancée</p>
          </main>
        </div>
        <div className="sect--2">
          <div className='section__image'>
            {active
              ? <img onMouseEnter={() => setActive(true)}
                onMouseOut={() => setActive(false)} src="/bg_hover.png" alt="" />
              : <img onMouseEnter={() => setActive(true)}
                onMouseOut={() => setActive(false)} src="/bg_.png" alt="" />}
          </div>
        </div>
      </div>
      <footer>
        <div className='social__media'>
          <a href="https://www.facebook.com/ComprimeeAgency" target="_blanck">
            <img src="/fbsvg.svg" alt="" />
          </a>
          <a href="https://www.instagram.com/comprimeeagency/" target="_blanck">
            <img src="/INSTAsvg.svg" alt="" />
          </a>
        </div>
        <div className="contact__info">
          <div>
            <img src="/phonesvg.svg" alt="" />
            <p>06 65 730 708</p>
          </div>
          <div>
            <img className="email" src="/emailsvg.svg" alt="" />
            <p> agency@comprimee.com</p>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Home